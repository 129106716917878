// Colours
$yellow: #FFCF0C;
$light_blue: #0694D3;
$purple: #525088;
$pink: #E94B3D;
$orange: #E68F1B;
$dark_blue: #29AFA5;

$category: #B296BE;

$primary: #EC619F;
$secondary: #0096D6;


// Fonts
body, p, li, h1, h2, h3, h4, h5, h6, input, select, textarea {
    font-family: "museo-sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body.error404 .site-content, body.search-no-results .site-content {
    background: $primary;
}

#page.site {
    margin: 0 auto;
    max-width: 1800px;
}

.site-main {
    margin-bottom: 0;
}

// Site header
.site-header {
    
    // Logo
    .site-branding {
        margin-bottom: 5px !important;
    }
    .site-link__img {
        width: 220px;
    }

    // Main menu navigation
    .main-navigation {
        a {
            text-transform: none;   
            letter-spacing: 0;
            font-size: 16px !important;
            font-weight: 500;
        }
        &.main-navigation:not(.vertical-menu) {
            .sub-menu {
                padding: 0;
                background: #FFF;
                & > li > a {
                    padding: 15px 35px;
                    color: #1b1b1b !important;
                    &:hover {
                        background: $primary;
                        color: #FFF !important;
                    }                
                }
                li.menu-item-has-children a:after {
                    top: 24px;
                }
            }
            .menu>li>.sub-menu {
                margin-top: 5px;
                padding: 0;
                background: #FFF;
                & > li > a {
                    padding: 15px 35px;
                    &:hover {
                        background: $primary;
                        color: #FFF;
                    }                
                }
            }
            
        }
    }
    
    .header-social {
        margin-top: 0;
        text-align: center;
        a {
            background: $primary;
            &:hover {
                background: darken($primary, 10%);
                transform: scale(1.2);
            }
        }
    }
    
    .header-search {
        border-left: none;
    }
    
    .search-form {
        .search-form__field {
            font-size: 1.5rem;  
            color: #333;
            &::placeholder {
                color: #CCC;
            }
        }           
    }
    
    &.search-active .mobile-panel { 
        .site-link__img {
            opacity: 0;
            visibility: hidden;
            transform: translateY(20px);
        }
        .search-form {
            margin-left: 0;
            left: 25px;
        }
    }
    
    .shopping-centre-information {
        display: none;
    }
    
    .header__mha {
        margin-top: 20px;
        background: #A30157;
        a {
            display: block;
            padding: 8px 0;
            text-align: center;
            text-decoration: none;
            color: #FFF;
            line-height: 50px; 
            font-size: 20px;
            img {
                height: 50px;
                margin-right: 10px;
            }
        }
    }
    
    @media screen and (max-width: 767px) {
    
        margin-top: 227px;
        
        .header-container, .header-container_wrap {
            padding: 0;
        }
        
        .header-container {
            position: fixed;
            z-index: 500;
            top: 95px;
            width: 100%;
        }
        
        .header-container_wrap {
            .header-container__top, .header-container__bottom {
                display: none;
                padding-left: 25px;
                padding-right: 25px;
            }
            .header-container__bottom {
                display: block;
            }
            .shopping-centre-information {
                display: block;
                position: relative;
                padding: 10px 25px;
                text-align: center;
                background: $secondary;
                color: #FFF;
                font-weight: 500;
                font-size: 16px;
                span {
                    position: relative;
                    z-index: 600;
                }
                i {
                    position: absolute;
                    bottom: -10px;
                    left: calc(50% - 8px);
                    z-index: 600;
                    color: #FFF;
                }
                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: $secondary;
                    bottom: -20px;
                    left: calc(50% - 30px);
                    z-index: 500;
                }
            }
            .contact-block {
                margin-top: 0;
                padding: 15px 0;
            }
            .header-icons {
                display: none;
                padding: 30px 0 25px;
                text-align: center;
                ul {
                    text-align: center;
                    width: 100%;
                    padding: 0 !important;
                    a {
                        width: 45px;
                        height: 45px;
                        padding-top: 3px;
                        font-size: 24px;
                    }
                }
            }
            .main-navigation.mobile-menu {
                padding-top: 95px;
                & > .menu {
                    overflow: visible;
                    & > li {
                        border-bottom: 1px solid darken($primary, 10%);
                        &:first-child {
                            margin-top: 0;
                        }
                        & > a {
                            text-align: center;
                            padding: 10px 25px;
                            font-size: 20px !important;
                            font-weight: 700;
                            color: #FFF;
                            &:hover {
                                background: lighten($primary, 5%);
                            }
                        }
                    }
                }
            }
        }

        .site-branding, .search-form__close:after {
            display: none;
        } 

        .mobile-panel {
            height: 95px;            
            .site-link__img {
                width: 120px;
                margin-left: 15px;
            }
            .mobile-panel__right {
                margin-top: 30px;
                position: relative;
            }
            svg {
                circle {
                    fill: $primary;
                }
                line, polyline {
                    stroke: $primary;
                    stroke-width: 3px;
                }
                polyline {
                    fill: none;                    
                }
            }
            .menu-toggle {
                position: relative;
                right: 0;
                top: 0;
                display: block;
                transform: rotate(0deg);
                width: 32px;
                height: 32px;
                padding: 0;
                span {
                    display: block;
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background: $primary;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: .25s ease-in-out;        
                    &:nth-child(1) {
                        top: 1px;
                    }
                    &:nth-child(2), &:nth-child(3) {
                        top: 15px;
                    }
                    &:nth-child(4) {
                        top: 28px;
                    }        
                }
                &.toggled {
                    span {
                        &:nth-child(1) {
                          top: 18px;
                          width: 0%;
                          left: 50%;
                        }
                        &:nth-child(2) {
                            transform: rotate(45deg);
                        }
                        &:nth-child(3) {
                            transform: rotate(-45deg);
                        }
                        &:nth-child(4) {
                            top: 18px;
                            width: 0%;
                            left: 50%;
                        }
                    }
                }
            }
            .header-search {
                .search-form__toggle {
                    display: inline;
                    position: static;
                    top: auto;
                    &:before {
                        display: none;
                    }
                }
                svg { 
                    position: absolute;
                    right: 50px;
                    top: 0;
                    circle {
                        fill: none;
                        stroke: $primary;
                        stroke-width: 3px;
                    }
                }
            }
            .social svg { 
                position: absolute;
                right: 102px;
                top: 0;
            }
            .mobile-panel__right a:first-child {
                padding-right: 0;
            }
        }

        .header-container__flex, .header-elements-wrap, .mobile-panel__right, .contact-block__inner {
            display: block;
        }
        
        .header__mha {
            margin-top: 0;
            a {
                position: relative; 
                padding: 25px 0 25px 80px;
                text-align: left;
                line-height: 1;
                font-size: 18px;
                img {
                    position: absolute;
                    left: 20px;
                    top: 17px;
                    height: 50px;
                    margin-right: 0;
                }
            }
        }
        
    }
    
    &.search-active .mobile-panel .header-search {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 95px;
        .search-form__toggle {
            display: none;
        }
    }
    
}

// Mobile menu bar
.admin-bar {
    @media screen and (max-width: 767px) {
        
        .site-header .header-container {
            top: 141px;
        }
        
        .site-header .header-container_wrap .main-navigation.mobile-menu {
            padding-top: 140px;
        }
        
        .mobile-panel, #wpadminbar {
            position: fixed !important;
        }
        
        .search-active .mobile-panel .header-search {
            top: 46px;
        }
        
    }
}

// Site content
.site-content_wrap {
    margin-top: 0 !important;
    h4 {
        font-size: 28px;
        margin: 30px 0 10px 0;
        &:first-of-type {
            margin-top: 0;
        }
    }
    ul, ol {
        margin-left: 2.5em;
        li {
            margin-bottom: 5px;
        }
    }
    table {
        td, th {
            padding: 5px 15px;
        }
    }
    & > .button {
        margin-top: 25px;
    }
}
.container {
    padding-left: 45px;
    padding-right: 45px;
    max-width: 1405px !important;
    @media screen and (max-width: 767px) {
        padding-left: 25px;
        padding-right: 25px;
    }
}

// Individual panels
.panel {
    padding: 90px 0;
    &.centered {
        text-align: center;
    }
    &.grey {
        background: #F0F4F5;
    }
    &.light-grey {
        background: #FAFAFA;
    }
    &.reduced-padding {
        padding: 90px 0 40px;
        @media screen and (max-width: 767px) {
            padding: 25px 0 15px;    
        }
    }
    &.narrow {
        padding: 50px 0; 
        h2 {
            margin-bottom: .5em;
        }
    }    
    &.logos {
        padding: 30px 0;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    h2 {
        font-size: 42px;
        font-weight: 700;
        color: #454545;
        margin-bottom: 1em;
        margin-top: -10px;
        span {
            color: $primary;
        }
        @media screen and (max-width: 767px) {
            font-size: 28px;
            margin: 0 0 .5em 0;
        }
    }
    &.heading-only h2 {
        margin: 0;
    }
    @media screen and (max-width: 767px) {
        padding: 25px 0;    
    }
}

// Hero images
img.hero, .hero-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    & > img {
        width: 100%;
    }    
}

@media screen and (max-width: 767px) {
    .single-retailers .hero-image {
        min-height: 250px;
        img {
            display: none;
        }
    }
}

// Social icon links in header and footer
.social-links {
    margin: 30px 0 0 0;
    padding: 0;
    list-style-type: none;
    li {
        display: inline-block;
        margin: 0 3px;
    }
    a {
        display: block;
        width: 30px;
        height: 30px;
        padding-top: 1px;
        border-radius: 50%;
        background: #666;
        color: #FFF;
        font-size: 18px;
        &:hover {
            background: $primary;
            transform: scale(1.2);
        }
    }
}

// Bootstrap carousels 
.carousel-indicators {
    bottom: 0;
    li {
        width: 15px;
        height: 15px;
        margin: 0 10px;
        cursor: pointer;
        border-radius: 50%;
        background: #FFF;
        border: 1px solid #FFF;
        transition: transform .3s ease-in-out;
        &.active {
            border: 1px solid $primary;
            background: lighten($primary, 40%);
            transform: scale(1.7);
        }
    }
}

// Bootstrap carousel arrows
.carousel-control-prev-icon, .carousel-control-next-icon {
    position: relative;
    background-image: none;
    padding: 40px;
    border-radius: 50%;
    background: $primary;
    margin-left: -40px;
    font-family: FontAwesome;
    font-size: 32px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #FFF;
    &:before {
        position: absolute;
        display: block;
        top: 15%;
    }
}

.carousel-control-prev, .carousel-control-next {
    z-index: 20;
}

.carousel-control-prev {
    justify-content: flex-start;
    .carousel-control-prev-icon:before {
        content: "\f104";
        left: 57%;
    }
}

.carousel-control-next {
    justify-content: flex-end;
    .carousel-control-next-icon {
        margin-left: 0;
        margin-right: -40px;
        &:before {
            content: "\f105";
            right: 57%;
        }
    }
}


.carousel-fade {
    .carousel-inner, .carousel-item {
        height: 32.7vw; // only works with 1800x600 images
        max-height: 600px;
    }
	.carousel-item {
		&, a { 
            display: block;
            position: absolute;
            top: 0;
            opacity: 0;
            transition: opacity .75s ease-in-out;
        }
		&.active {
			&, a {
                opacity: 1;
                z-index: 14;
            }
		}
        img {
            width: 100%;
        }
	}
}


/***************
*   HOMEPAGE   *
***************/
#homepage_mobile_carousel {
    img {
        width: 100%;
    }
    .carousel-indicators {
        display: none;
    }
    .carousel-inner, .carousel-item {
        height: 87vw; // only works with 600x520 images
        max-height: 498px;
    }
}

.homepage_featured-retailers {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    a {
        display: flex;
        flex-basis: 16.66%;
        justify-content: center;
        img {
            transition: opacity .2s ease-in-out;
            max-width: 155px;
            max-height: 70px;
            width: 95%;
            @media screen and (max-width: 1180px) {
                width: 75%;
            }
        }
        *::-ms-backdrop, img {
            width: auto;    
        }
        &:hover img {
            opacity: .75;
        }
    }
}

.homepage_jumpoff {
    margin-bottom: 50px;    
    img {
        float: left;
        width: 100%;
    }
    figcaption {
        text-align: center;
        padding: 8px 10px;
        color: #FFF;
        clear: left;
        font-weight: 700;
        font-size: 24px;
    }
    &.yellow figcaption {
        background: url('../images/block_yellow.png') $yellow;
    }
    &.light-blue figcaption {
        background: url('../images/block_light-blue.png') $light_blue;
    }
    &.purple figcaption {
        background: url('../images/block_purple.png') $purple;
    }
    &.pink figcaption {
        background: url('../images/block_pink.png') $pink;
    }
    &.orange figcaption {
        background: url('../images/block_orange.png') $orange;
    }
    &.dark-blue figcaption {
        background: url('../images/block_dark-blue.png') $dark_blue;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 25px;    
    }
}

.homepage__video {
    h2 {
        text-align: center;
        font-size: 32px;
        margin-top: 10px;
        border-bottom: 1px solid #DDD;
        padding-bottom: 15px;
    }
    iframe {
        height: 52vw;
        max-height: 670px;
        margin-bottom: 50px;
    }
}

// Offers
.offer {
    background: #FFF;
    box-shadow: 3px 3px 4px #CCC;
    min-height: 450px;
    margin-bottom: 30px;
    & > a {
        color: #777;
        & > div {
            padding: 25px;
        }
    }
    .offer_image {
        display: flex;
        align-items: center;
        padding: 50px;
        min-height: 185px;
        img { 
            width: 90%;
            max-height: 85px;
            margin: 0 auto;
        }
        *::-ms-backdrop, img {
            width: auto;    
        }
    }
    .offer_title {
        text-align: center;
        color: #FFF;
        font-weight: 700;
        font-size: 22px;
        padding: 10px 5px;
        &.yellow {
            background: url('../images/block_yellow.png') $yellow;
        }
        &.light-blue {
            background: url('../images/block_light-blue.png') $light_blue;
        }
        &.purple {
            background: url('../images/block_purple.png') $purple;
        }
        &.pink {
            background: url('../images/block_pink.png') $pink;
        }
        &.orange {
            background: url('../images/block_orange.png') $orange;
        }
        &.dark-blue {
            background: url('../images/block_dark-blue.png') $dark_blue;
        }
    }
    .offer_valid {
        background: #F6F8F9;
        color: $primary;
        font-size: 16px;
        strong {
            display: block;
            color: #444;
            font-size: 20px;
        }
    }
    .offer_description p {
        line-height: 140%;
    }
    &.no-description {
        min-height: inherit;
    }
}

body.home .offer .offer_image {
    *::-ms-backdrop, img {
        width: 90%;    
    }
}

.job, .search-result {
    border: 1px solid #DDD;
    margin-bottom: 30px;
    min-height: 340px;
    .job_logo {
        background: #F4F4F4;
        padding: 50px;
        img { 
            width: 90%;
            max-height: 105px;
            margin: 0 auto;
        }
        *::-ms-backdrop, img {
            width: auto;    
        }
    }
    .job_title, .result_title {
        padding: 15px 25px;
        background: url('../images/block_job.png') $pink;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        span {
            display: block;
            font-size: 14px;
             font-weight: 500;
        }
    }
    .job_description, .result_description {
        padding: 20px 25px;
        font-size: 14px;
    }
    .button {
        background: $primary;
        &:hover {
            background: darken($primary, 5%);
        }
    }
}

.search-result {
    text-align: center;
    min-height: 190px;
    .result_title {
        background: $pink;
    }
    &.job_vacancies .result_title {
        background: $primary;
    }
    &.offers .result_title {
        background: $yellow;
    }
    &.retailers .result_title {
        background: $light_blue;
    }
    &.post .result_title {
        background: $purple;
    }
}

.fb {
    h2 {
        margin-bottom: 5px;
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
    h3 {
        font-size: 32px;
        @media screen and (max-width: 767px) {
            font-size: 22px;   
        }
    }
    .fb__content {
        border-bottom: 1px solid #CCC;
        padding-bottom:  15px;
        margin-bottom: 15px;
    }
    .fb__stats {
        margin: 0 0 2em 0;
        list-style-type: none;
        li {
            display: inline-block;
            margin-right: 30px;
            font-size: 14px;
            span, i {
                color: $primary;
            }
        }
    }
}

/****************
*   RETAILERS   *
****************/

.filter-form {
    padding-right: 200px;
    position: relative;
    .filter-form__surround {
        background: #FFF;
        min-height: 52px;
        padding: 5px 15px;
        & > div {
            float: left;
            img {
                height: 32px;
                width: 32px;
                margin: 5px 0;
            }
            &.filter-form__search {
                border-right: 1px solid #DDD;
                padding-right: 15px;
                margin-right: 15px;
            }
            &.filter-form__filters {
                width: calc(100% - 127px);
                text-align: left;
                span {
                    position: relative;
                    display: inline-block;
                    height: 32px;
                    background: #ECF1F4;
                    border-radius: 16px;
                    padding: 5px 30px 5px 10px;
                    margin: 5px 10px 5px 0;
                    color: #666;
                    font-size: 14px;
                    cursor: pointer;
                    transition: all .2s ease-in-out;
                    &:hover {
                        background: #A4ADBB;
                        &, i {
                            color: #FFF;
                        }
                    }
                    i {
                        position: absolute;
                        top: 9px;
                        right: 12px;
                        color: #999;
                        transition: all .2s ease-in-out;
                    }
                }
                .placeholder {
                    height: 32px;
                    padding: 10px 0;
                    font-size: 14px;
                    color: #999;
                }
                input[type=search] {
                    height: 22px;
                    padding: 10px 0;
                    font-size: 14px;
                    color: #333;
                    width: 100%;                
                    border-bottom: none;
                }

            }
            &.filter-form__dropdown {
                border-left: 1px solid #DDD;
                padding-left: 15px;
                margin-left: 14px;
                height: 42px;
                svg {
                    cursor: pointer;
                    height: 32px;
                    width: 32px;
                    margin: 5px 0;
                    circle {
                        fill: $primary;
                    }
                    &:hover circle {
                        fill: darken($primary, 5%);
                    }
                }
                .dropdown-toggle:after {
                    display: none;
                }
            }
        }        
    }
    .store-map {
        position: absolute;
        right: 0;
        top: 0;
        height: 52px;
        padding-top: 13px;
    }
    &.no-store-map {
        padding-right: 0;
    }
    &.text-search {
        padding-right: 0;
        position: relative;
        width: calc(100% - 25px);
        .filter-form__filters {
            width: calc(100% - 176px);
        }
        .button {
            position: absolute;
            top: 0;
            right: -25px;
            height: 52px;
            padding: 13px 40px;
        }
    }
    @media screen and (max-width: 767px) {
        padding-right: 0;
        .store-map {
            position: static;  
            width: 100%;
            margin-top: 15px;
        }
    }
}

.search__no-results {
    display: none;
    span {
        display: block;
        text-align: center;
        font-size: 28px;
        padding-bottom: 40px;
    }
}

.retailers {
    .retailer {
        margin-bottom: 50px;
        .retailer_image {
            position: relative;
            display: flex;
            align-items: center;
            padding: 50px;
            min-height: 205px;
            background: #F4F4F4;
            color: #454545;
            .concession {
                font-size: 12px;
                display: block;
                background: #E6E6E6;
                padding: 5px 10px;
                position: absolute;
                top: 0;
                width: 100px;
                left: calc(50% - 50px);
            }
            img { 
                width: 90%;
                max-height: 105px;
                margin: 0 auto;
            }
            *::-ms-backdrop, img {
                width: auto;
                height: auto;
                max-width: 17vw;
                @media screen and (max-width: 940px) {
                    max-width: 15vw;
                }
                @media screen and (max-width: 767px) {
                    max-width: 28vw;
                }
            } 
            span {
                display: block;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
        figcaption {
            position: relative;
            padding: 15px 0;
            border-bottom: 1px solid #DDD;
            h3 {
                margin: 0;
                font-size: 20px;
                color: #454545;
            }
            .categories {
                position: absolute;
                top: 12px;
                right: 0;
                padding-left: 15px;
                border-left: 1px solid #DDD;
                img {
                    width: 30px;
                }
            }
        }
    }
}




/**************************
*   INDIVIDUAL RETAILER   *
**************************/
.single_retailer {
    .retailer_logo {
        position: relative;
        top: -120px;
        width: 100%;
        max-width: 350px;        
        padding: 40px 60px;
        margin: 0 auto -100px;
        background: #FFF;
        border-radius: 90px;
        img {
            max-height: 140px;
        }
        @media screen and (max-width: 767px) {
            top: -80px; 
            margin-bottom: -60px;
        }
    }
    hr {
        margin: 10px 0 30px;
    }
    .concession-logo {
        max-width: 120px;
        margin: 30px 0 10px;
    }
    .retailer_container {
        margin: 0 auto;
        max-width: 700px;
        p {
            text-align: left;            
        }
        .centered-paragraphs p {
            text-align: center;
        }
        &.gallery {
            max-width: 1050px;
        }
        &.opening h4 {
            margin-top: 50px !important;
        }
        &:nth-of-type(2) {
            margin-top: 50px;
        }
    }
    .retailer_categories {
        margin-bottom: 25px;
        span {
            display: inline-block;
            width: 30px;            
            margin: 0 3px;
        }
        svg {
            width: 30px;
            * {
                fill: #FFF;
            }
            & > circle:first-child {
                fill: $category;
            }
        }
    }
    .concessions_container {
        max-width: 980px;
        margin: 50px auto 0;
        .retailer_image {
            display: flex;
            align-items: center;
            padding: 20px;
            min-height: 100px;
            background: #F4F4F4;
            color: #454545;
            img { 
                width: 90%;
                max-height: 60px;
                margin: 0 auto;
            }
            *::-ms-backdrop, img {
                width: auto;
                height: auto;
                max-width: 17vw;
                @media screen and (max-width: 940px) {
                    max-width: 15vw;
                }
                @media screen and (max-width: 767px) {
                    max-width: 28vw;
                }
            } 
            span {
                display: block;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }
    h2 {
        color: $secondary;
        font-size: 42px;
        margin: 0 0 5px 0;
        @media screen and (max-width: 767px) {
            font-size: 32px;
            margin-bottom: 10px;
        }
    }
    h3 {
        color: #454545;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 30px;
        @media screen and (max-width: 767px) {
            font-size: 20px;    
        }
    }
    h4 {
        color: $secondary;
        text-transform: none;
        font-size: 22px;
        margin: 50px 0 10px 0;
    }
    .retailer_buttons {
        margin: 50px 0;
        .button {
            margin: 0 15px 15px;
            background: $primary;
            &:hover {
                background: darken($primary, 5%);
            }
            &.inverted {
                background: transparent;
                border: 1px solid $primary;
                color: $primary;
            }
        }
    }
    .retailer_share {
        margin-top: 25px;
        h3 {
            margin-bottom: 0;
            font-size: 18px;
        }
        .social-links {
            margin: 25px 0 0 0;
            a {
                background: #DDD;
                &:hover {
                    background: #BBB;
                    transform: scale(1.2);
                }
            }
        }
    }
    .offer_image {
        margin-top: 50px;
        img {
            display: block;
            margin: 0 auto;
        }
    }
}

.opening_hours {
    position: relative;
    width: 100%;
    text-align: left;
    padding: 4px 20px;
    background: #FFF;
    border-radius: 30px;
    span {
        position: absolute;
        right: 15px;
    }
    &.active {
        background: $secondary;
        color: #FFF;
    }
    @media screen and (max-width: 767px) {
        font-size: 14px;    
    }
}

/************
*   POSTS   *
************/

.posts {
    .post {
        & > a {
            display: block;
            width: 100%;
        }
        .retailer_image {
            min-height: 205px;
            background-position: center center;
            background-size: cover;
            background-color: #F4F4F4;
            padding: 0;
            display: block;
            img {
                width: 100%;
            }
        }
        figcaption { 
            min-height: 95px;
            position: relative;
            padding: 15px 55px 15px 0;
            margin-bottom: 30px;
            border-bottom: 1px solid #DDD;
            h3 {
                margin: 0;
                font-size: 20px;
                color: #454545;
                font-weight: 700;
            }
            h4 {
                font-size: 14px;
                text-transform: none;
                margin: 0;
                color: $primary;
            }
            .categories {
                position: absolute;
                top: 12px;
                right: 0;
                height: 70px;
                padding-left: 15px;
                border-left: 1px solid #DDD;
                img {
                    width: 30px;
                    margin-top: 20px;
                }
            }
            &.no-categories {
                padding: 15px 0;
            }
        }
    }
}

/**********************
*   INDIVIDUAL POST   *
**********************/

.single_job_vacancy, .single_post {
    h2 {
        color: #454545;
        span {
            color: $secondary;
        }
    }
}

.competition_terms {
    margin-top: 50px;
    h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    p {
        font-size: 14px;
    }
}

.post_gallery {
    margin-top: 50px;
    a {
        display: inline-block;
        margin: 0 10px 20px 10px;
    }
}


.button {
    display: inline-block;
    padding: 8px 25px;
    background: $secondary;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #FFF;
    &:hover {
        color: #FFF;
        background: darken($secondary, 10%);
    }
    &.inverted {
        background: transparent;
        border: 1px solid darken($secondary, 15%);
        color: darken($secondary, 15%);
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &.offers {
        margin: 25px 0;
    }
}

/*******************
*   SERVICE PAGE   *
*******************/
.service-anchor {
    display: block;
    height: 145px;
    margin-top: -145px;
    visibility: hidden;
}
.admin-bar .service-anchor {
    height: 190px;
    margin-top: -190px;
}

/*********************
*   VISIT US PAGES   *
*********************/
#visit-us-map {
    height: 35vw;
    max-height: 500px;
    min-height: 200px;
    @media screen and (max-width: 767px) {
        display: none;    
    }
}

.visit-us-pills .nav-pills {
    display: block;
    text-align: center;
    margin: 0;
    li {
        display: inline-block;
        margin: 0 8px;
        @media screen and (max-width: 767px) {
            margin: 5px 0;    
        }
    }
    .nav-link {
        padding: 8px 30px;
        border: 1px solid $primary;
        border-radius: 25px;
        font-size: 16px;
        background: #FFF;
        &.active {
            background: $primary;
            color: #FFF;
        }
    }
    @media screen and (max-width: 767px) {
        display: none;    
    }
}

.service-dropdown, .visit-us-dropdown {
    display: none;
    button {
        width: 100%;
        margin: 5px 0;
        text-align: left;
        &:after {
            float: right;
            margin-top: 4px;
        }
    }
    .dropdown-menu {
        width: 100%;
        .dropdown-item {
            padding: 10px 25px;
        }
    }
    @media screen and (max-width: 767px) {
        display: block;    
    }
}

.visit_us_container {
    h4 {
        color: #454545;
        margin: 0;
        & + h5 {
            margin-top: 15px;
        }
    }
    h5 {
        color: $primary;
        font-size: 18px;
        margin-bottom: 0;
    }
    .directions-nav {
        display: block;
        text-align: center;
        list-style-type: none;
        border-bottom: 0;
        margin: 0 0 40px 0;
        li {
            display: inline-block;
            margin: 0 10px;
            border-right: 1px solid #DDD;
            padding: 0 25px 0 0;
            a {
                display: block;
                background: #C1ABCB;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                padding: 0;
                border: none !important;
                &.active {
                    background: $primary;
                }
            }
            svg {
                width: 80%;
                height: 80%;
                max-height: 30px;
                max-width: 30px;
                margin-top: 10px;
            }
            &:last-child {
                border-right: none;
                padding-right: 0;
            }
            @media screen and (max-width: 767px) {
                margin: 0 5px;    
                padding: 0 20px 0 0;
            }
        }        
    }
    .tab-content {
        h5 {
            margin: 0 0 40px 0 !important;
        }
        p {
            font-size: 16px;
        }
    }
}

body.modal-open .modal {
    display: flex !important;
    height: 100%;
} 

body.modal-open .modal .modal-dialog {
    margin: auto;
}

.grecaptcha-badge {
    visibility: hidden;
}

// Site footer
.site-footer.style-2 {
    
    background: none;
    
    .footer-container {
        background: #f0f4f5;;
        padding: 50px 0;
        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
        .col-md-4 {
            padding-left: 15px;
            padding-right: 15px;
        }
        @media screen and (max-width: 767px) {
            padding: 25px 0;    
        }
    }
    
    .row > div:not(:last-child) {
        border-right: 1px solid #DDD;
    }
    
    #contact {
        button {
            text-align: right;
            padding-right: 5px;
            padding-top: 5px;
        }
        .modal-dialog {
            width: 50%;
            @media screen and (max-width: 767px){
                width: 90%;
            }
        }
        .modal-content {
            background-color: #f0f4f5;
        }
        .modal-body {
            padding: 0px 5% 0px 5% !important;
            h2 {
                font-size: 42px !important;
            }
            span {
                font-size: 8px;
                display: block; 
                overflow: hidden; 
                padding: 5px 0px;
            }
            @media screen and (max-width: 767px){
               h2 {
                    font-size: 28px !important;
               } 
            }
        }
        .wpcf7-form {
            p {
                margin-bottom: 0;
                display: block;
                font-size: 0.875rem;
            }
            label {
                width: 100%;
            }
            input:not([type=submit]), select, textarea {
                width: 100%;
                padding: 10px 15px;
                background-color: white;
                border: none;
                border-radius: 0 !important;
                -webkit-appearance: none;
            }
            input[type=submit] {
                display: block;
                width: 100px;
                padding: 10px 5px;
                background: $primary;
                border-radius: 0 !important;
                -webkit-appearance: none;
            }
            @media screen and (max-width: 767px){
                textarea {
                    height: 100px !important;
                    min-height: 100px !important; 
                }
             }
        }
    }

    .footer-block {
        position: relative;
        padding-left: 25px;
        text-align: left;
        
        & > i {
            position: absolute;
            left: 0;
            top: 2px;
            font-size: 18px;
            color: $primary;
        }
        
        h6 {
            font-size: 15px;
            margin-bottom: 5px;
            i {
                display: none;
            }
        }
        
        p {
            line-height: 135%;
            a {
                color: #777;
                &:hover {
                    color: #333;
                }
            }
        }        
        
    }    
    
    .footer-copyright {
        margin-top: 30px !important;
    }
    
    .credit {
        display: block;
        text-align: center;
        margin: 25px 0;
        color: #777777;        
    }
    
    @media screen and (max-width: 767px) {
        .footer-block {
            margin-bottom: 15px; 
            padding-bottom: 10px;
            border-bottom: 1px solid #CCC;
            h6 {
                position: relative;
                font-size: 18px;
                cursor: pointer;
                i {
                    display: block;
                    position: absolute;
                    top: 3px;
                    right: 0;
                }
            }
            p {
                display: none;  
                font-size: 18px;
                margin-bottom: 5px;
            }
        }
        .footer-social {
            text-align: center;
            a {
                width: 45px;
                height: 45px;
                padding-top: 3px;
                font-size: 24px;
            }
        }
    }
    
}

#cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    padding: 10px 15px;
    p {
        text-align: center;
        color: #FFF;
    }
    a {
        display: inline-block;
        background: $primary;
        color: #FFF;
        margin-left: 5px;
        padding: 5px 10px;
        text-decoration: none !important;
    }
}

#toTop {
    right: 20px;
}

// Overwrite bootstrap columns
.row {
    margin-left: -25px;
    margin-right: -25px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: 25px;
    padding-right: 25px;
}

.hidden-xs-up{display:none!important}@media (max-width:575px){.hidden-xs-down{display:none!important}}@media (min-width:576px){.hidden-sm-up{display:none!important}}@media (max-width:767px){.hidden-sm-down{display:none!important}}@media (min-width:768px){.hidden-md-up{display:none!important}}@media (max-width:991px){.hidden-md-down{display:none!important}}@media (min-width:992px){.hidden-lg-up{display:none!important}}@media (max-width:1199px){.hidden-lg-down{display:none!important}}@media (min-width:1200px){.hidden-xl-up{display:none!important}}.hidden-xl-down{display:none!important}